<template>
  <Button 
    class="signin-signup-button-text" 
    type="button"
    @click="handleGoogleLogin"
    :label="$t('components.templates.sign.signup.signupWithGoogleButtonLabel')"
    :icon="'pi pi-google'"
  />
</template>

<script>
import { getAuth, signInWithCredential, GoogleAuthProvider } from 'firebase/auth'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import { useStore } from 'vuex'
import { googleTokenLogin } from 'vue3-google-login'
import axios from "axios"

export default {
  name: 'GoogleSignIn',
  components: {
    Button
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const auth = getAuth()

    const handleGoogleLogin = async () => {
      try {
        console.debug('Attempting Google sign in...')
        const googleUser = await googleTokenLogin()
        console.debug('Google sign in response:', googleUser)

        // Get the ID token
        const idToken = googleUser.access_token
        
        // Create Firebase credential
        const credential = GoogleAuthProvider.credential(null, idToken)
        
        // Sign in to Firebase with credential
        const result = await signInWithCredential(auth, credential)
        const user = result.user
        
        // Check if this is a new user (sign up)
        if (result.additionalUserInfo?.isNewUser) {
          console.debug('New Google user signed up - pushing to data layer')
          window.dataLayer.push({
            'event': 'sign_up',
            'method': 'Google',
            'user_id': user.uid,
            'user_email': user.email,
            'event_category': 'engagement',
            'event_label': 'Google Sign Up',
            'debug_timestamp': new Date().getTime()
          });
          console.debug('Data layer after Google signup:', window.dataLayer)
        }
        
        // Store the access token if calendar scope is present
        if (googleUser.scope?.includes('calendar')) {
          await store.commit('setGoogleAccessToken', idToken)
        }
        
        // Update store with user
        await store.commit('setUser', user)

        // Check user status in starchat service
        try {
          const headers = {
            "Content-type": "application/json; charset=UTF-8",
            "auth": user.accessToken
          }
          const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/business/search"
          console.debug('Checking business status:', url)
          
          const response = await axios.post(url, 
            { offset: 0, limit: 1 },
            { headers }
          )

          // Set admin role if header indicates it
          if (response.headers["x-mrcall-role"] === "admin") {
            store.commit('setRole', 'admin')
          } else {
            store.commit('setRole', 'owner')
          }
          
          console.debug('Business search response:', response.data)
          const needsOnboarding = !response.data || response.data.length === 0
          
          if (needsOnboarding) {
            console.debug('User needs onboarding, redirecting to /onboardinglang')
            await router.replace('/onboardinglang')
          } else {
            console.debug('User has businesses, redirecting to /businesses')
            await router.replace('/businesses')
          }
        } catch (error) {
          console.error('Error checking user status:', error)
          
          // Handle specific error cases like in Businesses.vue
          if (error.response?.status === 401) {
            console.debug('Unauthorized, logging out user')
            await store.dispatch('logout')
            router.replace('/signin')
          } else {
            // On other errors, default to onboarding
            console.debug('Error occurred, defaulting to onboarding')
            await router.replace('/onboardinglang')
          }
        }
      } catch (error) {
        console.error('Google sign in error:', error)
      }
    }

    return {
      handleGoogleLogin
    }
  }
}
</script> 